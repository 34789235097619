<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-validation="extraValidation"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="accountFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{
              $t('Contact Information')
            }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="contactInformationFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
// import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import storeModule from '../userStoreModule'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
    // UserEditTabInformation,
    // UserEditTabSocial,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        participant: {
          country: { id: 263, title: 'Greece' },
        },
        enabled: true,
        memberType: { id: 1, title: 'Τακτικό' },
      },
      entityLoaded: false,
      countryOptions: [],
      companyOptions: [],
      userGroupOptions: [],
      genderOptions: [
        { id: 1, title: 'Άνδρας' },
        { id: 2, title: 'Γυναίκα' },
      ],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'users',
        endpoint: 'users',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
      }
    },

    accountFormFields() {
      return [
        // {
        //   id: 'profilePhoto',
        //   parent: 'participant',
        //   type: 'cropupload',
        //   label: `${this.$t('Image')} (512x512)`,
        //   colSize: 6,
        //   width: 512,
        //   height: 512,
        //   autoupload: true,
        // },
        {
          id: 'firstName',
          parent: 'participant',
          type: 'text',
          label: this.$t('First Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'lastName',
          parent: 'participant',
          type: 'text',
          label: this.$t('Last Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          parent: 'participant',
          type: 'email',
          label: this.$t('email'),
          required: true,
          rules: 'email',
          colSize: 3,
        },
        // {
        //   id: 'email2',
        //   parent: 'participant',
        //   type: 'email',
        //   // eslint-disable-next-line prefer-template
        //   label: this.$t('email') + ' 2',
        //   rules: 'email',
        //   colSize: 3,
        // },
        {
          id: 'userGroup',
          object: true,
          type: 'select',
          label: this.$t('Usergroup'),
          options: this.userGroupOptions,
          required: true,
          colSize: 3,
        },
        // {
        //   id: 'gender',
        //   parent: 'participant',
        //   type: 'select',
        //   label: this.$t('gender'),
        //   options: this.genderOptions,
        //   colSize: 3,
        // },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 1,
        },
        {
          id: 'changePassword',
          type: 'checkbox',
          label: this.$t('change password'),
          hidden: !this.entity.id,
          colSize: 2,
        },
        {
          id: 'password',
          type: 'password',
          label: this.$t('password'),
          required: !this.entity.id || this.entity.changePassword,
          rules: 'password',
          colSize: 3,
          dependency: this.entity.id ? 'changePassword' : null,
        },
      ]
    },
    contactInformationFormFields() {
      return [
        {
          id: 'country',
          object: true,
          parent: 'participant',
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'city',
          parent: 'participant',
          type: 'text',
          label: this.$t('city'),
          colSize: 3,
        },
        {
          id: 'region',
          parent: 'participant',
          type: 'text',
          label: this.$t('region'),
          colSize: 3,
        },
        {
          id: 'address',
          parent: 'participant',
          type: 'text',
          label: this.$t('address'),
          required: false,
          colSize: 3,
        },
        {
          id: 'postalCode',
          parent: 'participant',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'mobile',
          parent: 'participant',
          type: 'text',
          label: this.$t('mobile'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'telephone',
          parent: 'participant',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'bio',
          parent: 'participant',
          type: 'quill',
          label: this.$t('bio'),
          required: false,
          colSize: 12,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchUserGroups').then(response => {
      this.userGroupOptions = response.data
    })
  },
  methods: {
    extraValidation() {
      if (this.entity.id && !this.entity.changePassword) {
        delete this.entity.password
      }
      return ''
    },
  },
}
</script>
